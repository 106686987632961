@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --MetallicBlue: #235d71;
    --darkblue: #09384f;
    --lightblue: #76b5d4;
    --green: #47f4c7;
    --greenHover: #68fcd4;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Ethiopic:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* .main{
    width: 100%;
    height: 100vh;
}
video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.content{
    position: absolute;
    width: 100%;
    height: 100%;
} */

.btnnid {
    color: azure;
    background-color: var(--green);
    transition: all 0.3s;
}

.btnnid:hover {
    scale: 1.02;
    background-color: var(--greenHover);
    color: rgb(235, 250, 250);
}

.test {
    background-color: #09384f;
}
.test:focus {
    background-color: rgb(255, 255, 255);
    color: #09384f;
}

.test:visited {
    background-color: rgb(255, 255, 255);
    color: #09384f;
}

.text:hover {
    background-color: azure;
}
.my-accordion .accordion-icon {
    color: red;
}

.scaled {
    transform: scale(1.1);
    transform-origin: center center;
    transition: transform 0.2s ease-in-out;
}

.cls1 {
    fill: #7d8b8f;
    stroke: #232323;
    stroke-miterlimit: 10;
    stroke-width: 0.25px;
    fill-rule: evenodd;
}

.cls2 {
    fill: #212830;
}

.cls3 {
    fill: greenyellow;
}

path {
    stroke: #232323;
    stroke-miterlimit: 10;
    stroke-width: 0.25px;
    fill-rule: evenodd;
}

path:hover {
    fill: "red";
}
